<template>
    <section class="preview-course">
        <div class="container-fluid text-left">
            <div class="d-flex">
                <video class="h-100 mx-auto" controls :src="src"></video>
            </div>
        </div>
    </section>
</template>

<script>
    import $ from 'jquery'
    import { base_url_machine_learning } from "../../../config/base_url";
    import { getStatusJob } from "@/services/course/course.service";

    export default {
        data() {
            return {
                src: ""
            }
        },
        mounted() {
            this.intervalGetStatus()
            this.setSideBar()

            this.$vs.loading({
                background: "#000000",
                color: "#ffffff",
                type: 'corners',
                text: 'Sedang diproses...'
            });
        },
        methods: {
            setSideBar() {
                $("section.preview-course").parent().addClass("m-0")
                this.$store.commit("IS_SIDEBAR_ACTIVE", false);
            },
            intervalGetStatus() {
                var vm = this;
                var job_id = localStorage.getItem("job_id");

                var myInterval = setInterval(() => {
                    getStatusJob(job_id)
                    .then(res => {
                        console.log(res);
                        console.log(res.data.status);
                        if(res.data.status != "on_process") {
                            clearInterval(myInterval);
                            this.$vs.loading.close();
                            localStorage.setItem("final_path", res.data.final_path)
                            vm.src = base_url_machine_learning + res.data.final_path;
                        }else{
                            console.log('masok sini');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })

                }, 5000);
            }
        }
    }
</script>