<template>
    <section class="detail-setup-quiz text-white">
        <div class="container-fluid text-left">
            <div class="row">
                <div class="col-3">
                    <div class="card mb-3 mt-5">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label type="button" @click="$bvModal.show('upload-image')">
                                        <div class="d-flex img-wrap">
                                            <img v-if="images_cover != '-' && images_cover != '' &&  images_cover != null && images_cover != undefined && images_cover != 'undefined'" :src="images_cover"  alt="" class="img-fluid">
                                            <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-fluid"/>
                                            <div class="img__description_layer">
                                                <p class="img__description">Click to change image</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex flex-column">
                                    <label for="txt-nama-quiz" :style="quiz_name == null || quiz_name == '' ? 'margin-bottom: 0rem' : ''">Quiz Name*</label>
                                    <span class="text-alert mb-2" v-if="$store.state.validationCreate == true">
                                        You have already created the Quiz name!
                                    </span>
                                    <span class="mb-2 text-alert" v-if="quiz_name == null || quiz_name == ''">
                                        Quiz Name cannot be empty!
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="txt-nama-quiz" :style="quiz_name == null || quiz_name == '' ? 'border: 2px solid #EB5757' : ''" v-model="quiz_name" />
                            </div>
                            <div class="form-group">
                                <label for="txt-deskripsi-quiz">Quiz Description</label>
                                <textarea v-model="quiz_description" class="form-control" id="txt-deskripsi-quiz" rows="4"></textarea>
                            </div>
                            <div class="form-group">
                                <div class="d-flex flex-column">
                                    <label for="dd-subject" :style="category_id == null || category_id == '' ? 'margin-bottom: 0rem' : ''">Topic*</label>
                                    <span class="mb-2 text-alert" v-if="category_id == null || category_id == ''">
                                        Topic cannot be empty!
                                    </span>
                                </div>
                                <select name="subject" id="dd-subject" class="form-control" :style="category_id == null || category_id == '' ? 'border: 2px solid #EB5757' : ''" v-model="category_id" @change="changeLearningCategories()">
                                    <option v-if="categories.length == 0" disabled>Tidak ada data</option>
                                    <option
                                        v-for="(value, index) in categories"
                                        :key="index"
                                        :value="value._id"
                                    >{{value.category_name}}</option>
                                </select>
                            </div>

                            <div v-if="question_type !== 'Code Test'" class="form-group mb-3">
                                <label for="">Grade level :</label>
                                <!-- <div class="w-100">
                                    <button v-for="(value, index) in list_grade" :key="index" type="button" class="btn btn-sm px-4 mr-2 mb-1 mt-2" :class="grade == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setGrade(value._id, index)"><small :class="grade == value._id ? 'text-white ': ''">{{value.grade}}</small></button>
                                </div> -->
                                <div class="w-100 d-flex align-items-center">
                                    <select name="subject" id="dd-subject" class="form-control" v-on:change="changeGrade" v-model="grade">
                                        <option v-if="list_grade.length == 0" disabled>No data to show</option>
                                        <option
                                        v-for="(value, index) in list_grade"
                                        :key="index"
                                        :value="value.grade"
                                        >{{value.grade}}</option>
                                    </select>
                                </div>
                            </div>
                            <template v-if="list_grade.length > 0 && question_type !== 'Code Test'">
                                <div class="form-group" v-if="list_grade[indexGrade].sub_grade.length > 0">
                                    <label for="">Sub Level :</label>
                                    <div class="w-100">
                                        <button v-for="(value, index) in list_grade[indexGrade].sub_grade" :key="index" type="button" class="btn btn-sm px-4 mr-2 mb-1 mt-2" :class="sub_grade == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setSubGrade(value._id)"><small :class="sub_grade == value._id ? 'text-white ': ''">{{value.sub_grade}}</small></button>
                                    </div>
                                </div>
                            </template>
                            <div v-if="question_type === 'Code Test'" class="form-group">
                                <span>Level</span>
                                <div class="w-100 mt-2">
                                    <button v-for="(value, index) in list_level" :key="index" type="button" class="btn btn-sm px-3 mr-2" :class="level_id == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setLevel(value._id)"><small :class="level_id == value._id ? 'text-white ': ''">{{value.level}}</small></button>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <label for="tag-list">Tags</label>
                                <input type="text" v-model="isiTag" id="tag-list" placeholder="Masukan tag" class="form-control" v-on:keyup.enter="addTag">
                                <div v-if="tagList.length > 0" class="container-fluid">
                                    <div class="row mt-2">
                                        <div v-for="(tag, index) in tagList" :key="index" class="col-auto my-1">
                                            <div class="tag-badge d-flex align-items-center mr-1">
                                                <div class="mx-2 my-1 d-flex align-items-center justify-content-center">
                                                    <p class="mb-0 mr-2">#{{ tag }}</p>
                                                    <img src="@/assets/images/icons/close-grey.svg" alt="" style="height: 12px;" type="button" @click="removeTag(index)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div v-if="this.listQuestion != null && this.listQuestion.length > 0" class="card">
                        <div class="card-body">
                            <h3>Import Pertanyaan</h3>
                            <h5 for="txt-deskripsi-quiz" class="my-2 font-weight-normal">Impor pertanyaan dari spreadsheet/excel</h5>
                            <p class="text-none">Harap unggah spreadsheet/excel yang mengikuti contoh template.</p>
                            <a href="/file/QuestionsUpload.xlsx" download>
                                <div class="excel d-flex mt-2 align-items-center text-decoration-none text-white" type="button">
                                    <img src="@/assets/images/logo/excel-logo.png" alt="" class="logo-excel">
                                    <p class="m-0 ml-2">Unduh Contoh Format</p>
                                </div>
                            </a>
                            <div v-if="users.subscribe != null && users.subscribe == true">
                                <button v-if="quiz_name != null && quiz_name != '' && category_id != null && category_id != ''" class="btn btn-primary btn-purple text-white btn-impor my-3" @click="$bvModal.show('impor-soal')"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                                <button v-else class="btn btn-primary btn-purple disabled text-white btn-impor my-3" style="cursor: not-allowed"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                            </div>
                            <div v-else>
                                <button v-if="this.listQuestion.length == 20 || this.listQuestion.length >= 20" @click="$bvModal.show('subs-modal')" class="btn btn-primary btn-purple text-white btn-impor my-3"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                                <button v-else-if="quiz_name != null && quiz_name != '' && category_id != null && category_id != ''" class="btn btn-primary btn-purple text-white btn-impor my-3" @click="$bvModal.show('impor-soal')"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                                <button v-else class="btn btn-primary btn-purple disabled text-white btn-impor my-3" style="cursor: not-allowed"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div v-if="this.listQuestion != null && this.listQuestion.length > 0" class="col-9">
                    <div class="head-card">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="question-total d-flex align-items-center">
                                <img src="@/assets/images/icons/ic_document.svg" alt="question" class="border-none invert">
                                <h3 class="font-weight-normal ml-2 mr-auto">{{ this.listQuestion.length }} Questions</h3>
                            </div>
                            <div>
                                <button type="button" class="btn btn-transparent btn-block my-2 text-center" @click="tambahPertanyaan()"><img src="@/assets/images/icons/ic_plus_purple.svg" class="mr-2"/><span style="font-size:18px" class="font-weight-bold">Add Question</span></button>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-pg" v-for="(data, index) in listQuestion" :key="index" v-katex:auto>
                        <div v-if="data.deleted == null" class="card" >
                            <div class="card-body py-3">
                                <div class="d-flex align-items-center mb-0">
                                    <div class="col-md-6">
                                        <span class="font-weight-normal type-question">{{index + 1}}. {{ data.question_type == "Multiple Choice" || data.question_type == null || data.question_type == "" ? titleMultipleChoices : data.question_type == "True or False" ? titleTrueFalse : data.question_type == "Code Test" ? titleCode : data.question_type == "Fill in the Blank" ? titleFillInTheBlank : titleMenjodohkan}} </span>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-end action-question">
                                        <div class="form-control mr-3 w-auto">
                                            <span class="mx-2 text-answer">
                                                {{ data.point ? data.point : '10' }} Point
                                            </span>
                                        </div>
                                        <div class="form-control mr-3 w-auto">
                                            <span class="mx-2 text-answer">
                                                {{ data.duration ? data.duration : '30' }} Seconds
                                            </span>
                                        </div>
                                        <button type="button" class="btn btn-action mr-3 d-flex align-items-center text-answer" @click="tambahPertanyaan(index)"><img src="@/assets/images/icons/Edit.svg" alt="Edit" class="mr-1 invert"><span class="text-white">Edit</span></button>
                                        <button type="button" class="btn btn-action" @click="modalDeleteQuestion(data._id, index)"><img src="@/assets/images/icons/ic_delete_2.svg" alt="Delete" ></button>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mb-3">
                                    <img v-if="data.image_url != null && data.image_url != ''" :src="data.image_url" :alt="data.image_url" class="image-url-style mr-2"/>
                                    <audio v-if="data.voice != null && data.voice != ''" :src="data.voice" controls></audio>
                                    <div v-if="data.video != null && data.video != ''" class="text-center w-100">
                                        <iframe width="50%" height="300" :src="data.video" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div v-if="data.question_type === 'Code Test'" class="mt-3">
                                        <CodeEditor
                                            :language="data.code_language || 'py'"
                                            :value="data.code"
                                            readonly
                                        />
                                    </div>
                                    <p v-if="data.question != 'Voice' && data.question != 'Video'" class="text-break mb-0 text-break text-questions"><span class="text-break ml-1" v-html="data.question"></span></p>
                                </div>
                                <div>
                                    <div>
                                        <p class="text-secondary-2 mb-0">Answer Choices</p>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="answer container-fluid w-100">
                                        <div class="row">
                                                <div v-if="data.question_type == 'Multiple Choice' || data.question_type == 'Code Test' || data.question_type == 'Fill in the Blank' || data.question_type == null || data.question_type == ''" class="col-12 container-fluid text-left">
                                                    <div class="row justify-content-start px-3">
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex text-answer">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">A. <span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex text-answer">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">B. <span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_3 != null && data.option_3 != ''" class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex text-answer">
                                                                <div>
                                                                    <span :class="data.correct_answer == '3' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">C. <span class="text-break ml-1 " v-html="data.option_3"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_4 != null && data.option_4 != ''" class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex text-answer">
                                                                <div>
                                                                    <span :class="data.correct_answer == '4' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">D.  <span class="text-break ml-1 " v-html="data.option_4"></span></p>
                                                            </div>
                                                        </div>
                                                        <!-- <vs-radio :color="data.correct_answer == '1' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_1">A. {{data.option_1}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '2' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_2">B. {{data.option_2}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '3' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_3">C. {{data.option_3}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '4' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_4">D. {{data.option_4}}</vs-radio> -->
                                                    </div>
                                                </div>
                                                <div v-else-if="data.question_type == 'True or False'" class="col-12 container-fluid text-left">
                                                    <div class="row justify-content-start px-3">
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex text-answer">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex text-answer">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="data.question_type == 'Match'" class="col-12 container-fluid text-left">
                                                    <table style="width:50%" border>
                                                        <tr>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_1 == null || data.img_option_1 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                                    <img v-else :src="data.img_option_1" alt="img1" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_2 == null || data.img_option_2 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                                    <img v-else :src="data.img_option_2" alt="img2" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_3 == null || data.img_option_3 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_3"></span></p>
                                                                    <img v-else :src="data.img_option_3" alt="img3" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_4 == null || data.img_option_4 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_4"></span></p>
                                                                    <img v-else :src="data.img_option_4" alt="img4" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_1"></span></p>
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_2"></span></p>
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_3"></span></p>
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_4"></span></p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-9 mt-5 text-center">
                    <h1>You don't have any questions yet.</h1>
                    <h1>Let's do it now!</h1>
                    <button  v-if="quiz_name != null && quiz_name != '' && category_id != null && category_id != ''" type="button" class="btn btn-primary btn-purple btn-lg mt-4 my-2 text-center" @click="tambahPertanyaanNew()"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Create Inquiry</button>
                    <button  v-else type="button" class="btn btn-primary btn-purple disabled btn-lg mt-4 my-2 text-center" style="cursor: not-allowed"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Create Inquiry</button>
                    <p>atau</p>
                    <div class="d-flex justify-content-center">
                        <div class="card w-75">
                            <div class="card-body">
                                <h4 class="my-2 font-weight-normal">Impor pertanyaan dari spreadsheet/excel</h4>
                                <h5 class="text-none font-weight-normal">Use template yang telah disediakan.</h5>
                                <a href="/file/QuestionsUpload.xlsx" download>
                                    <div class="excel d-flex mt-2 align-items-center justify-content-center text-decoration-none text-dark" type="button">
                                        <img src="@/assets/images/logo/excel-logo.png" alt="" class="logo-excel">
                                        <p class="m-0 ml-2 text-success">Unduh Contoh Format</p>
                                    </div>
                                </a>
                                <div v-if="users.subscribe != null && users.subscribe == true" class="d-flex justify-content-center">
                                    <button v-if="quiz_name != null && quiz_name != '' && category_id != null && category_id != ''" class="btn btn-primary btn-purple text-white btn-impor my-3 d-flex align-items-center" @click="$bvModal.show('impor-soal')"> <font-awesome-icon icon="fa-file-download" class="mr-1 ml-3" /><p class="mb-0 mr-3">Import Question</p></button>
                                    <button v-else class="btn btn-primary disabled text-white btn-impor my-3 d-flex align-items-center" style="cursor: not-allowed"> <font-awesome-icon icon="fa-file-download" class="mr-1 ml-3" /><p class="mb-0 mr-3">Import Question</p></button>
                                </div>
                                <div v-else class="d-flex justify-content-center">
                                    <button v-if="this.listQuestion.length == 20 || this.listQuestion.length >= 20" @click="$bvModal.show('subs-modal')" class="btn btn-primary btn-purple text-white btn-impor my-3 d-flex align-items-center"> <font-awesome-icon icon="fa-file-download" class="mr-1 ml-3" /><p class="mb-0 mr-3">Import Question</p></button>
                                    <button v-else-if="quiz_name != null && quiz_name != '' && category_id != null && category_id != ''" class="btn btn-primary btn-purple text-white btn-impor my-3 d-flex align-items-center" @click="$bvModal.show('impor-soal')"> <font-awesome-icon icon="fa-file-download" class="mr-1 ml-3" /><p class="mb-0 mr-3">Import Question</p></button>
                                    <button v-else class="btn btn-primary btn-purple disabled text-white btn-impor my-3 d-flex align-items-center" style="cursor: not-allowed"> <font-awesome-icon icon="fa-file-download" class="mr-1 ml-3" /><p class="mb-0 mr-3">Import Question</p></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL UPLOAD IMG CVR -->
        <b-modal id="upload-image" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <!-- <div class="my-4 mx-4">
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                </div>
            </div> -->
            <div class="position-relative mt-2">
                <button class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                <vs-tabs :color="colorx">
                    <vs-tab label="Gallery">
                         <div class="con-tab-ejemplo gallery mt-2">
                            <label for="search">
                                <input type="text" v-model="filterThumb" class="search-bar" placeholder="Find thumbnail" @change="searchImage(filterThumb)">
                            </label>
                            <div v-if="unsplashImage.length == 0" class="container-fluid overflow-auto container-thumbnail mt-3">
                                <div v-if="masterThumbnails.length > 0" class="row">
                                    <div v-for="(data, index) in masterThumbnails" :key="index" class="col-md-3 mb-3 px-0">
                                        <div class="mr-2 ml-2">
                                            <button class="btn-thumbnail" @click="fromThumbnail(data.file, data.name)">
                                                <img :src="data.file" :alt="data.name" class="img-fluid thumbnail-img"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="h-100 d-flex align-items-center justify-content-center">
                                    <p class="mb-0 text-muted">Image not found</p>
                                </div>
                            </div>
                            <div v-else class="container-fluid overflow-auto container-thumbnail mt-3">
                                <b-card-group columns v-if="unsplashImage.length > 0">
                                    <b-card v-for="(data, index) in unsplashImage" :key="index" class="px-0" body-class="p-0 m-0">
                                        <button class="btn-thumbnail" @click="fromThumbnail(data.urls.thumb, data.description)" style="width: 100%; height: 100%;">
                                            <img :src="data.urls.thumb" class="thumbnail-img" style="width: 100%; height: 100%;"/>
                                        </button>
                                    </b-card>
                                </b-card-group>
                                <div v-else class="h-100 d-flex align-items-center justify-content-center">
                                    <p class="mb-0 text-muted">Image not found</p>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Upload">
                         <div class="con-tab-ejemplo" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                            <b-alert variant="danger" :show="showAlertImg" fade dismissible>
                                {{ responseValidateImg }}
                            </b-alert>
                            <label @drop="dragImage($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3">
                                <div v-if="images_cover_before != '-' && images_cover_before != '' &&  images_cover_before != null && images_cover_before != undefined" class="h-100 d-flex align-items-center">
                                    <div>
                                        <img :src="images_cover_before"  alt="" class="img-form">
                                        <small class="my-auto ml-2 text-white">{{ typeof imageFileBefore === "string" ? 'cover_image.jpg' : imagenameBefore }}</small>
                                    </div>
                                </div>
                                <div v-else class="h-100 d-flex align-items-center">
                                    <div>
                                        <img src="@/assets/images/logo/paper_impor.svg" alt="">
                                        <h4 class="font-weight-normal mt-2 text-blue">Choose image</h4>
                                        <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                                    </div>
                                </div>
                                <input type="file" style="display: none" accept="image/jpeg, image/png" @change="imageUpload($event)"/>
                            </label>
                        </div>
                    </vs-tab>
                </vs-tabs>
                <hr style="background: #2d2d2d;">
                <div class="float-right">
                    <button class="btn btn-secondary btn-md btn-grey mr-2" @click="cancelImage"><p class="mb-0 mx-2">Cancel</p></button>
                    <button class="btn btn-purple btn-md text-white" @click="saveImage"><p class="mb-0 mx-2">Use</p></button>
                </div>
            </div>
        </b-modal>

        <!-- MODAL IMPOR -->
        <b-modal id="impor-soal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="form-row">
                <b-form-group class="col-md-12">
                    <b-alert variant="danger" :show="showAlert" fade dismissible>
                        {{responseValidate}}
                    </b-alert>
                </b-form-group>
            </div>
            <div class="d-flex justify-content-between my-4 mx-4">
                <div class="text-left">
                    <h3 class="font-weight-bold">Impor pertanyaan dari spreadsheet/excel</h3>
                    <h5 class="mb-0 mt-2 font-weight-normal">Harap unggah spreadsheet/excel yang mengikuti contoh template.</h5>
                </div>
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('impor-soal')"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <div class="mx-4" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                <label @drop="dragFile($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center">
                    <div v-if="filename == ''">
                        <img src="@/assets/images/logo/paper_impor.svg" alt="">
                        <h4 class="font-weight-normal mt-2 text-blue">Choose file</h4>
                        <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                    </div>
                    <div v-else>
                        <img src="@/assets/images/logo/excel.png" alt="">
                    </div>
                    <input type="file" style="display: none" ref="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="fileUpload($event)"/>
                    <h5 class="mt-3">{{ typeof formData.file === "string" ? formData.file : filename }}</h5>
                </label>
                <div class="d-flex align-items-center justify-content-between py-3">
                    <a href="/file/QuestionsUpload.xlsx" download>
                        <div class="d-flex align-items-center text-decoration-none text-dark">
                            <img src="@/assets/images/logo/excel.png" alt="" class="img-logo">
                            <h4 class="font-weight-normal ml-2">Unduh contoh format</h4>
                        </div>
                    </a>
                    <div class="d-flex align-items-center">
                        <button class="btn btn-secondary text-dark">Cancel</button>
                        <button v-if="filename == ''" class="btn btn-secondary text-white ml-2" style="cursor : not-allowed;" disabled>Impor</button>
                        <button v-else class="btn btn-warning text-white ml-2" @click="importQuestion()">Impor</button>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- MODAL SIMPAN KE DRAFT -->
        <b-modal id="modal-draft" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('modal-draft')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/data-none.png" alt="sovia-delete">
                <div class="mt-2">
                    <h5 class="font-weight-bold">Konten kamu hampir selesai.</h5>
                    <h5 class="font-weight-bold">Ingin menyimpannya sebagai draft?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('modal-draft')"><p class="mb-0">Hapus</p></button>
                    <button class="btn btn-primary btn-purple-nb"><p class="mx-2 mb-0">Save to Draft</p></button>
                </div>
            </div>
        </b-modal>

        <!-- DELETE QUESTION -->
        <b-modal id="delete-question" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <!-- <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('delete-question')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div> -->
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/data-none.png" alt="sovia-delete">
                <div class="mt-2">
                    <h5 class="font-weight-bold">Are you sure</h5>
                    <h5 class="font-weight-bold">to delete this question ?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('delete-question')"><p class="mb-0">No</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="deleteQuestion()"><p class="mx-2 mb-0">Yes</p></button>
                </div>
            </div>
        </b-modal>
        <b-overlay :show="loadingCreate" no-wrap spinner-variant="primary" spinner-type="grow" rounded="sm" style="position:fixed !important;">
        </b-overlay>
    </section>
</template>
<script>
import $ from "jquery"
import {AlertUtils} from '@/mixins/AlertUtils';

import {getQuizCreatorById} from '@/services/quiz-creator/quiz-creator.service'
import {getCategoriesAll} from '@/services/quiz-categories/quiz_categories.service'
import {getMasterThumbnails} from '@/services/master-thumbnails/master-thumbnails.service'
import {getLearningCategories} from '@/services/learning-categories/learning-categories.service'
import { uploadQuestionCreator } from '@/services/question-creator/question_creator.service'
import { getGrades } from '@/services/generative-ai/generative-ai.service'
import { getSearchImage } from "@/services/course/course.service";
// import {getMasterGradeAll} from '@/services/master-grades/master-grades.service'

import { mapGetters } from "vuex";

import CodeEditor from '@/components/code-editor/CodeEditor';

export default {
    components: {
        CodeEditor,
    },
    mixins: [AlertUtils],
    layouts: {
        default: '~/views/layouts/empty.vue',
    },
    data(){
        return {
            dataQuiz : {
                category_id: null,
                learning_category_id: null,
                grade_id: null,
                level: null,
                quiz_name: null,
                author:null,
                images_cover:null,
                categories_quiz: {
                    category_name: null
                }
            },
            question_type: null,
            quiz_name: null,
            quiz_description: null,
            category_id: null,
            images_cover: null,
            filterThumb: null,
            index_question_delete: null,
            id_question_delete: null,
            grade: '',
            sub_grade : '',
            level_id: "",
            indexGrade: 0,

            categories: [],
            list_grade: [],
            list_level: [],
            dataQuestion:[],
            learnings:[],
            listQuestion: [],
            newQuestion: [],
            oldQuestion: [],
            realQuestion: [],
            tagList : [],
            masterThumbnails : [],
            isiTag : null,
            newIdQuiz: null,
            responseValidate: "",
            showAlert: false,
            responseValidateImg: "",
            showAlertImg: false,
            filename: "",
            imagename: "",
            imagenameBefore: "",
            imageFileBefore: "",
            images_cover_before: "",
            colorx: 'rgb(188, 59, 242)',
            formData: {
                file: "",
            },
            imageFile : "",
            titleMultipleChoices : "Multiple Choice",
            titleTrueFalse : "True or False",
            titleCode : "Code Test",
            titleFillInTheBlank : "Short Answers",
            titleMenjodohkan : "Menjodohkan",
            users: {},
            thumbnail: [ "reproduksi.svg","metabolisme.svg", "tkj.svg", "sosiologi.svg", "geografi.svg", "Digital Marketing.svg", "Bahasa Mandarin.svg", "Bahasa Jepang.svg" ],

            // Unsplash
            perPageUnsplash: 30,
            pageUnsplash: 1,
            unsplashImage : [],
        }
    },
    watch: {
        quiz_name(newQuizName) {
            localStorage.quiz_name = newQuizName;
        },
        quiz_description(newQuizDescription) {
            localStorage.quiz_description = newQuizDescription;
        },
        images_cover(newImagesCover) {
            localStorage.images_cover = newImagesCover;
        },
        category_id(newCategoryID) {
            localStorage.category_id = newCategoryID;
        },
        listQuestion(newListQuestion) {
            localStorage.listQuestionCreate = JSON.stringify(newListQuestion);
        },
        sub_grade(newSubGrade){
            localStorage.sub_grade = newSubGrade;
        },
        level_id(newLevelId){
            localStorage.levelAi = newLevelId
        }
        // isiTag(newTag){
        //     localStorage.createTag = JSON.stringify(newTag)
        // }
    },
    mounted(){
        this.Id()
        this.getMasterGrade()
        this.setSideBar()
        this.cekId()
        this.getThumbnail()
        this.getLevels();
        this.getLearningCategories(this.$store.state.dataQuiz.category_id)
        // this.searchImage()
        $("section.detail-setup-quiz").parent().addClass('m-0');

        if(localStorage.getItem('users')){
            this.users = JSON.parse(localStorage.getItem('users'))
        }

        if(localStorage.quiz_name){
            this.quiz_name = localStorage.quiz_name
        }
        if(localStorage.quiz_description){
            this.quiz_description = localStorage.quiz_description
        }
        if(localStorage.images_cover){
            this.images_cover = localStorage.images_cover
        }
        if(localStorage.category_id){
            this.category_id = localStorage.category_id
        }

        if(localStorage.createTag){
            this.tagList = JSON.parse(localStorage.createTag)
        }
        if(localStorage.grade){
            this.grade = localStorage.grade
        }
        if(localStorage.sub_grade){
            this.sub_grade = localStorage.sub_grade
        }
        if(localStorage.levelAi){
            this.level_id = localStorage.levelAi;
        }
        if(localStorage.index_grade){
            this.indexGrade = localStorage.index_grade
        }
        if(localStorage.listQuestionCreate){


            if(this.$store.state.canceledCreate == true) {
                this.listQuestion = JSON.parse(localStorage.backUpCreateQuestion)
                this.$store.state.canceledCreate = false
                localStorage.setItem('lengthQuestionAi', this.listQuestion.length)
            } else {
                this.listQuestion = JSON.parse(localStorage.listQuestionCreate)
                localStorage.setItem('lengthQuestionAi', this.listQuestion.length)
            }

            this.question_type = this.listQuestion[0].question_type;

        }

        this.getCategories()
    },
    computed: {
        searchThumb() {
            if (this.filterThumb) {
                return this.masterThumbnails.filter((item) => {
                return this.filterThumb
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.masterThumbnails;
            }
        },

        ...mapGetters({
            loadingCreate : 'loadingCreate'
        }),
    },
    methods: {
        cekId(){
            const id = this.$route.query.key
            if(id != undefined){
                this.getDetailQuiz(id)
            }
        },
        changeGrade(event){
                const selectedIndex = event.target.selectedIndex;

                this.setGrade(this.list_grade[selectedIndex]._id, selectedIndex)
        },
        back(){
            this.$router.push({ name : 'My-Quiz' })
        },
        dragFile(e) {
            const file = [...e.dataTransfer.files];
            const fileSize = file[0].size;

            if (
                file[0].type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file[0].type == "application/vnd.ms-excel"
            ) {
                if (fileSize > 25000000) {
                    this.showAlert = true;
                    this.responseValidate = "Ukuran file melebihi 25MB";
                } else {
                    this.showAlert = false;
                    this.formData.file = file[0];
                    this.filename = file[0].name
                        ? file[0].name
                        : "Undefined";
                }
            } else {
                this.responseValidate = "The file must be in XLS or XLSX format";
                this.showAlert = true;
                return false;
            }
        },

        getThumbnail(){
            getMasterThumbnails()
            .then((response) =>{
                if(response.status && response.status == true){
                    this.masterThumbnails = response.data
                }
            })
        },

        dragImage(e){
            const file = [...e.dataTransfer.files];

            let size = file[0].size;

            let type = file[0].type;
            if (
                type == "image/png" ||
                type === "image/jpeg"
            ) {
                if (size > 1000000) {
                    this.alertFail(this.$t("file-size-max-1mb"));
                } else {
                    this.showAlertImg = false;
                    this.imagenameBefore = file[0].name
                        ? file[0].name
                        : "Undefined";
                    this.imageFileBefore = file[0];
                    this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                    this.convertImage(this.imageFileBefore, 'images_cover')
                }
            } else {
                this.showAlertImg = true;
                this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
            }
        },

        importQuestion(){
            let formData = new FormData();
            formData.append('uploadfile', this.formData.file);

            uploadQuestionCreator(formData).then((response) => {
                var tempArray = []
                if(localStorage.listQuestionCreate != null){
                    tempArray = JSON.parse(localStorage.listQuestionCreate)
                    var data = response.data
                    data.forEach(data => {
                        var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id
                        data._id = num
                        tempArray.push(data)
                    });

                    localStorage.listQuestionCreate = JSON.stringify(tempArray)
                    this.listQuestion = JSON.parse(localStorage.getItem("listQuestionCreate"))
                    this.filename = "",
                    this.formData = {
                        file: "",
                    }
                } else {
                    var excelData = []
                    excelData = response.data
                    excelData.forEach((e) => {
                        var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id
                        e._id = num
                    })

                    localStorage.listQuestionCreate = JSON.stringify(excelData)
                    this.listQuestion = JSON.parse(localStorage.getItem("listQuestionCreate"))
                    this.$store.state.check_length = this.listQuestion.length
                    this.filename = "",
                    this.formData = {
                        file: "",
                    }
                }

            })

            this.$bvModal.hide('impor-soal')

        },

        fileUpload(e){
            const fileSize = e.target.files[0].size;
            var file = e.target.files[0];
            if (e.lengthComputable) {
                console.log(e.loaded / e.total * 100);
            }
            if (
                file.type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type == "application/vnd.ms-excel"
            ) {
                if (fileSize > 25000000) {
                    this.showAlert = true;
                    this.responseValidate = "Ukuran file melebihi 25MB";
                } else {
                    this.showAlert = false;
                    this.formData.file = e.target.files[0];
                    this.filename = e.target.files[0].name
                        ? e.target.files[0].name
                        : "Undefined";
                }
            } else {
                this.responseValidate = "The file must be in XLS or XLSX format";
                this.showAlert = true;
                return false;
            }
        },

        getDetailQuiz(id){
            getQuizCreatorById(id)
            .then(response => {
                if(response.status && response.status == true){
                    this.$store.state.dataQuiz = response.data

                    if(response.data.topic) {
                        this.category_id = response.data.topic
                    }
                }
                this.getLearningCategories(this.$store.state.dataQuiz.category_id)
            }).catch(error => {
                console.log(error)
            })
        },
        getCategories(){
            if (this.question_type === 'Code Test') {
                this.categories = [
                    {
                        _id: '1',
                        category_name: 'Python',
                    },
                    {
                        _id: '2',
                        category_name: 'JavaScript',
                    },
                    {
                        _id: '3',
                        category_name: 'Java',
                    },
                    {
                        _id: '4',
                        category_name: 'C',
                    },
                    {
                        _id: '5',
                        category_name: 'C++',
                    },
                ];
            } else {
                getCategoriesAll()
                .then(response => {
                    if(response.status && response.status == true){
                        this.categories = response.data
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        getLevels() {
            this.list_level = [
                {
                    _id: '1',
                    level: 'Basic',
                },
                {
                    _id: '2',
                    level: 'Intermediate',
                },
                {
                    _id: '3',
                    level: 'Expert',
                },
            ];
        },
        getMasterGrade(){
            getGrades()
            .then((response) => {
                if(response.status == true) {
                    this.list_grade = response.data
                }
            })
            .catch((err) => {
                this.$vs.loading.close();
                console.log("data err", err);
            });
        },
        getLearningCategories(id){
            if (this.question_type === 'Code Test') {
                this.learnings = [
                    {
                        _id: '1',
                        category_name: 'Python',
                    },
                    {
                        _id: '2',
                        category_name: 'JavaScript',
                    },
                    {
                        _id: '3',
                        category_name: 'Java',
                    },
                    {
                        _id: '4',
                        category_name: 'C',
                    },
                    {
                        _id: '5',
                        category_name: 'C++',
                    },
                ];
            } else {
                getLearningCategories(id)
                .then(response => {
                    if(response.status && response.status == true){
                        this.learnings = response.data
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        changeLearningCategories(){
            this.learnings = []
            this.getLearningCategories(this.category_id)
        },
        setSideBar(){
            this.$store.commit('IS_SIDEBAR_ACTIVE', false);
        },
        tambahPertanyaan(index){
            var id = this.$route.query.key

            if(id != undefined){
                if(index == undefined ){

                    this.$router.push({ name: 'Update-Question-Creator', query: {key:id}})
                } else{
                    this.$router.push({ name: 'Update-Question-Creator', query: {key:id, index: index+1}})
                }
            } else {

                if(index == undefined ){
                    this.$store.state.createData.question = null
                    this.$store.state.createData.option_1 = null
                    this.$store.state.createData.option_2 = null
                    this.$store.state.createData.option_3 = null
                    this.$store.state.createData.option_4 = null
                    this.$store.state.createData._id = null
                    this.$store.state.createData.correct_answer = null
                    this.$store.state.createData.image_url = null
                    this.$store.state.createData.video = null
                    this.$store.state.createData.voice = null
                    this.$store.state.createData.point = 10
                    this.$store.state.createData.duration = 30

                    localStorage.setItem('backUpCreateQuestion', JSON.stringify(this.listQuestion))
                    this.$router.push({ name: 'Create-Question-Creator'})
                } else{
                    localStorage.setItem('backUpCreateQuestion', JSON.stringify(this.listQuestion))

                    this.$router.push({ name: 'Create-Question-Creator', query: {index: index+1}})
                }
            }
            let setData = localStorage.getItem("listQuestionCreate");
            if(!setData){
                localStorage.setItem('listQuestionCreate', JSON.stringify(this.listQuestion))
            }



        },

        tambahPertanyaanNew(){
            localStorage.removeItem('listQuestionCreate')
            this.$router.push({ name: 'Create-Question-Creator'})
            this.$store.state.createData = {
                question_type : 'Multiple Choice',
                quiz_id : '',
                question : '',
                option_1 : '',
                option_2 : '',
                option_3 : '',
                option_4 : '',
                voice : '',
                video : '',
                correct_answer : '',
                point: 10,
                duration : 30
              }
        },

        // Set data question, while user cancelled add new questions
        Id(){
            let dataQuestion = localStorage.getItem("listQuestionCreator");
            this.$store.state.listQuestion = JSON.parse(dataQuestion);
        },
        deleteItem(id, index){
            if(id.toString().length <= 5){
                this.listQuestion.splice(index,1)
                localStorage.setItem('listQuestionCreate', JSON.stringify(this.listQuestion))
            } else {
                const updateQ = this.listQuestion.find(e => e._id === id)
                this.listQuestion.splice(index,1)
                updateQ.deleted = true
                this.listQuestion.push(updateQ)
                localStorage.setItem('listQuestionCreate', JSON.stringify(this.listQuestion))
            }
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },

        convertImage(file, form) {
            var vm = this;
            var reader = new FileReader();
            reader.onloadend = function () {
                switch (form) {
                    case 'images_cover':
                        vm.images_cover_before = reader.result
                        break;
                    default:
                        break;
                }
            };
            reader.readAsDataURL(file);
        },

        imageUpload(e) {
            let size = e.target.files[0].size;

            let type = e.target.files[0].type;
            if (
                type == "image/png" ||
                type === "image/jpeg"
            ) {
                if (size > 1000000) {
                    this.alertFail(this.$t("file-size-max-1mb"));
                } else {
                    console.log("AMAN", size)
                    this.showAlertImg = false;
                    this.imagenameBefore = e.target.files[0].name
                        ? e.target.files[0].name
                        : "Undefined";
                    this.imageFileBefore = e.target.files[0];
                    this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                    this.convertImage(this.imageFileBefore, 'images_cover')
                }
            } else {
                this.showAlertImg = true;
                this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
            }
        },
        saveImage(){
            this.imagename = this.imagenameBefore
            this.imageFile = this.imageFileBefore
            this.images_cover = this.images_cover_before

            this.imagenameBefore = ""
            this.imageFileBefore = ""
            this.images_cover_before = ""
            this.$bvModal.hide('upload-image')
        },
        addTag(e){
            if(e.target.value != null && e.target.value != '' ) {
                this.tagList.push(e.target.value)
                localStorage.setItem('createTag', JSON.stringify(this.tagList))
            }
            this.isiTag = ''
        },
        removeTag(index){
            this.tagList.splice(index,1)
            localStorage.setItem('createTag', JSON.stringify(this.tagList))
        },
        fromThumbnail(image, name){
            this.imagenameBefore = name
            this.imageFileBefore = image
            this.images_cover_before = image
            // this.images_cover = this.images_cover_before
        },
        cancelImage(){
            this.imagenameBefore = ""
            this.imageFileBefore = ""
            this.images_cover_before = ""
            this.showAlertImg = false;
            this.$bvModal.hide('upload-image')
        },

        setGrade(value, index) {
            this.indexGrade = index
            this.grade = this.list_grade[index].grade
            if(this.list_grade[index].sub_grade.length > 0) {
                this.sub_grade = this.list_grade[index].sub_grade[0]._id
            }else{
                this.sub_grade = ''
            }
            localStorage.setItem("index_grade", index)
            localStorage.setItem("grade", this.grade)
            localStorage.setItem("gradeAI", this.grade)
        },

        setSubGrade(value){
            this.sub_grade = value
            localStorage.setItem("sub_grade", value)
        },

        setLevel(val) {
            this.level_id = val;
        },

        modalDeleteQuestion(id, index){
            if(index != null){
                this.index_question_delete = null;
            }

            if(id != null){
                this.id_question_delete = null;
            }

            this.index_question_delete = index;
            this.id_question_delete = id;
            this.$bvModal.show('delete-question')
        },

        deleteQuestion(){
            this.deleteItem(this.id_question_delete, this.index_question_delete)
            this.$bvModal.hide('delete-question')
        },

        searchImage(input){
            var vm = this

            getSearchImage(input, vm.perPageUnsplash, vm.pageUnsplash)
            .then((response) => {
                if(response.status){
                    vm.unsplashImage = response.data.results
                } else {
                    vm.unsplashImage = []
                }
            }).catch((err) => {
                vm.unsplashImage = []
                console.log("data err", err);
            });
        }
    }
}
</script>

<style scoped>

.text-answer{
    font-size: 12px;
}
.text-questions{
    font-size: 14px;
    font-weight: 600;
}

.type-question{
    font-size: 14px;
}
.text-alert{
    font-size: 12px !important;
    color: #EB5757;
}
button, .btn{
    box-shadow: none;
}
    table, th, td {
        border: 0;
    }
    tr,td{
        padding-top: 1rem;
    }

    .invert{
        filter: invert(100%);
    }

    .form-control{
        background-color: #222222;
        color: white;
        border-color: #383838;
        box-shadow: none;
    }

    .btn-action{
        background-color: #2D2D2D;
    }

    .action-question .form-control{
        background-color: transparent;
        border: 1px solid #2D2D2D;
        color: white
    }

    hr{
        background : #2D2D2D;
    }

    .text-secondary-2{
        color: #8C8C8C
    }

    .correct-active{
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #40BE45;
    }

    .answer-def{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #D9D9D9;
    }

    .img-option{
        height: 3.5rem;
        width: 3.75rem;
        border-radius: 6px;
    }

    .image-url-style{
        width: 6rem;
        height: 6rem;
    }

    .incorrect-active{
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #EB5757;
    }

    .label {
        font-size: 1.15rem;
    }

    .btn-purple-pill {
        color: white;
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        height: 1.9rem;
        border-radius: 4x;
    }

    .btn-grey-pill {
        background-color: transparent;
        border: 1px solid #2D2D2D;
        color:#8C8C8C !important;
        height: 1.9rem;
        border-radius: 4x;
    }

    .img-form {
        width: 70px;
        height: 70px;
    }

    .text-blue{
        color: #2B87E3;
    }

    .text-none{
        font-size: 14px;
        color: #636363;
    }

    .head-card {
        height: 3rem;
    }

    .row .col-3, .row .col-9 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .container-image {
        position: relative;
        width: 55%;
    }
    .container-image:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .btn-impor-custom{
        border : 2px dashed #2D2D2D;
        height: 20rem;
        border-radius: 10px;
    }

    .btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }
    .container-image img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .select-badge{
        width: auto;
        border: 0;
        border-radius: 15px;
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        background-color: #E5E5E5;
        -moz-appearance:none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .tag-badge{
        width: auto;
        border: 0;
        border-radius: 15px;
        background-color: #2F2F2F;
        -moz-appearance:none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        height: 28px
    }

    .questions {
        margin-bottom: 1rem;
    }

    .img-logo{
        width: 1.5rem;
        height: 1.5rem;
    }

    .questions .card {
        border-radius: 10px;
    }

    .questions .card .card-body {
        padding-left: 2.25rem;
    }

    .logo-excel{
        width: 18px;
        height: 18px;
    }

    .btn-impor{
        border-radius: 25px;
    }

    .card {
        border-radius: 10px;
    }

    .search-bar {
        height: 3rem;
        width: 100%;
        color: #ffffff;
        border-radius: 25px;
        background:transparent;
        border: 1px solid #2D2D2D;
        padding-left: 48px !important;
        padding-right: 20px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
    }

    .gallery label {
        position: relative;
        width: 100%;
        height: 40px;
        border-radius: 25px !important;
        z-index: 2;
    }

    .gallery label:before {
        content: "";
        position: absolute;
        left: 1rem;
        top: 10px;
        bottom: 0;
        width: 40px;
        background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
        background-size: 20px;
    }

    .thumbnail-img {
        border-radius: 10px 10px 10px 10px
    }

    .btn-thumbnail{
        background-color: transparent;
        border: none;
    }

    .btn-thumbnail:focus img {
        border: 3px solid #BC3BF2;
        border-radius: 10px;
    }

    .container-thumbnail{
        height: 28rem;
    }

    .container-thumbnail::-webkit-scrollbar {
        width: 6px;
    }

        /* Track */
    .container-thumbnail::-webkit-scrollbar-track {
        background: #636363;
        border-radius: 4px;
    }

        /* Handle */
    .container-thumbnail::-webkit-scrollbar-thumb {
        background: #B6B6B6;
        border-radius: 4px;
        background-clip: padding-box;
    }

        /* Handle on hover */
    .container-thumbnail::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    .close-image{
        right : 5px;
        z-index: 100;
    }

    .btn-grey{
        background-color: #2D2D2D !important;
        color: white;
        border: 0px !important;
    }

    .img-wrap{
        position: relative;
    }

    .img-wrap img{
        border-radius: 10px;
    }

    .img__description_layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        /* transition effect. not necessary */
        transition: opacity .2s, visibility .2s;
    }

    .img-wrap:hover .img__description_layer {
        visibility: visible;
        opacity: 1;
    }

    .img__description {
        transition: .2s;
        transform: translateY(1em);
    }

    .img-wrap:hover .img__description {
        transform: translateY(0);
    }

    .btn-purple {
        background-color: #6D75F6 !important;
        border: 1px solid #6D75F6 !important;
        color: white;
    }

    .btn-transparent {
        background-color: transparent;
        color: #6D75F6;
        display: flex;
        align-items: center;
    }

    .label-paq {
        background: transparent;
        border: 1px solid white;
        width: auto;
    }
</style>

<style>
    #upload-image .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
</style>