import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getMasterGradeAll(){
    return axios({
        method : 'GET',
        url : ApiRoute.masterGrades + '/all'
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }

  export async function getMasterSubGrade(id){
    return axios({
      method : 'GET',
      url : ApiRoute.getSubGrade + '/' + id
    }).then((res) => {
      const data = {
          status:res.data.status, 
          data:res.data.data, 
          message:res.data.message
      }
      return data;
    }).catch((error) => {
      const data = {status : false , response : error }    
      return data;
    })
  }