<template>
    <section class="detail-setup-quiz">
        <div class="container-fluid text-left">
            <div class="row" style="background: #2f2f2f; min-height: 93.8vh">
                <div class="slide-preview col-2 p-3">
                    <div class="row px-2 mb-3" v-for="(data, index) in slides" :key="index">
                        <div class="col-12 text-right mb-1" v-if="index == slideActive && !isPreview && !isLoadingProcess">
                            <img src="@/assets/images/icons/ic_copy.svg" alt="" class="cursor-pointer" @click="duplicateSlide(index)">
                            <img src="@/assets/images/icons/ic_trash.svg" alt="" class="ml-1 cursor-pointer" @click="deleteSlide(index)" v-if="slides.length > 1">
                        </div>
                        <div class="d-flex w-100">
                            <h6 class="text-number-slides mr-2">{{ index + 1 }}</h6>
                            <div :id="'slide-content-small-'+index" class="cursor-pointer" :class="index == slideActive ? 'slide-content-small-active' : 'slide-content-small'" @click="activeSlide(index)" :style="'background: '+formData.background.value">
                                <h1 class="text-content-small-index mt-2">{{index+1}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="row px-2" v-if="!isPreview && !isLoadingProcess">
                        <div class="d-flex w-100">
                            <h6 class="text-number-slides mr-2" style="visibility: hidden;">1</h6>
                            <div class="slide-content-small cursor-pointer" @click="addSlide">
                                <img src="@/assets/images/icons/add-slide.svg" alt="" class="mb-1">
                                <h6 class="text-content-small mt-2">Tambah Slide</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-slide col-6 p-4">
                    <div class="slide-content-wrapper mb-4">
                        <div id="slide-content" class="slide-content w-100" :style="'background: '+formData.background.value">
                            <div class="h-100 w-100 d-flex position-relative" v-if="slide_images.length == 0 && slides[slideActive].text.length == 0 && slides[slideActive].image.length == 0">
                                <div class="overlay-slide d-flex position-absolute w-100 h-100" v-if="isPreview && !isPlayPreview">
                                    <img src="@/assets/images/icons/ic_play_music.svg" class="m-auto img-play" alt="" @click="playPreview()">
                                </div>
                                <div class="m-auto" v-else-if="!isPreview">
                                    <img src="@/assets/images/icons/image-bordered.svg" alt="" class="mb-2">
                                    <h5 class="mt-2">Belum ada data yang ditambahkan</h5>
                                </div>
                            </div>
                            <div class="h-100 w-100 position-relative" v-else>
                                <div id="avatar-container" ref="avatarContainer"></div>
                                <div class="overlay-slide d-flex position-absolute w-100 h-100" v-if="isPreview && !isPlayPreview">
                                    <img src="@/assets/images/icons/ic_play_music.svg" class="m-auto img-play" alt="" @click="playPreview()">
                                </div>

                                <!-- Text -->
                                <template v-if="slides[slideActive].text.length > 0 && !isPreview">
                                    <vue-draggable-resizable :id="'textbox-'+slideActive+'-'+index" :class="index == textActive ? 'active' : ''" :w="100" :h="100" @dragging="onDrag" @resizing="onResize" :parent="true" v-for="(data, index) in slides[slideActive].text" :key="index" :style="'transform: translate('+data.x+'px, '+data.y+'px)'">
                                        <!-- <p>
                                            Hello! I'm a flexible component. You can drag me around and you can resize me.<br>
                                            X: {{ x }} / Y: {{ y }} - Width: {{ width }} / Height: {{ height }}
                                        </p> -->
                                        <textarea name="" :id="'textarea-'+slideActive+'-'+index" cols="30" rows="10" :style="'width: '+data.width+'px; height: '+data.height+'px; background: transparent; border: none; color: '+data.color" v-model="data.value" @click="activeText(index)"></textarea>
                                    </vue-draggable-resizable>
                                </template>
                                <template v-else>
                                    <p v-for="(data, index) in slides[slideActive].text" :key="index" name="" :id="'textarea-'+slideActive+'-'+index" cols="30" rows="10" :style="'width: '+data.width+'px; height: '+data.height+'px; background: transparent; border: none; color: '+data.color+'; transform: translate('+data.x+'px, '+data.y+'px); position: absolute; z-index: auto; user-select: auto;box-sizing: border-box; display: block;'">{{data.value}}</p>
                                </template>

                                <!-- Image -->
                                <template v-if="slides[slideActive].image.length > 0 && !isPreview">
                                    <vue-draggable-resizable v-for="(data, index) in slides[slideActive].image" :key="index" :class="data == imageActive ? 'active' : ''" :w="100" :h="100" @dragging="onDragImage" @resizing="onResizeImage" :parent="true" :style="'transform: translate('+data.x+'px, '+data.y+'px)'">
                                        <img :src="data.src" :style="'width: '+data.width+'px; height: '+data.height+'px; background: transparent; border: none;'" @mousedown="imageActive = data" @dblclick="slides[slideActive].image.splice(index, 1)">
                                    </vue-draggable-resizable>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="slide-content-script px-5 pt-3 pb-4">
                        <vs-tabs>
                            <vs-tab label="Masukkan script">
                                <div class="con-tab-ejemplo">
                                    <textarea class="script p-2 text-white w-100" name="" id="" cols="30" rows="10" placeholder="Input atau tempel script kamu disini" v-model="slides[slideActive].script"></textarea>
                                </div>
                            </vs-tab>
                            <vs-tab label="Gunakan rekaman suara">
                            </vs-tab>
                        </vs-tabs>
                        <button class="btn btn-proses" type="button" v-if="isLoadingProcess" disabled>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Proses
                        </button>
                        <button class="btn btn-proses" v-if="!isLoadingProcess && !isPreview" @click="submitScript">Proses</button>
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-flex h-100">
                        <div class="my-4 mr-4 p-4 toolbar-content w-100 position-relative">
                            <div :class="toolbar == 'avatars' ? '' : 'd-none'">
                                <h6>Humans Avatar</h6>
                                <div class="toolbar-content-detail">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Sovia.svg" alt="" class="w-100" @click="chooseAvatar()" :class="isAvatar ? 'avatar-active' : ''">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Mutia.svg" alt="" class="w-100">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Syarif.svg" alt="" class="w-100">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Guru PNS.svg" alt="" class="w-100">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Guru MTK.svg" alt="" class="w-100">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Guru Olahraga.svg" alt="" class="w-100">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Jokowi.svg" alt="" class="w-100">
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer">
                                            <img src="@/assets/images/avatars/Guru Silat.svg" alt="" class="w-100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="toolbar == 'background' ? '' : 'd-none'">
                                <h6 class="mb-2">Warna Latar</h6>
                                <div class="row m-0 mb-4">
                                    <div id="color_wrapper" class="col-1">
                                        <input id="color_picker" @input="set_color($event)" type="color" data-color="black" class="mr-2" v-model="formData.background.value">
                                    </div>
                                    <div class="col-11 pl-3">
                                        <input type="text" class="form-control border-none border-radius w-100" style="background-color: #2F2F2F; color: #8C8C8C;" v-model="formData.background.value" readonly>
                                    </div>
                                </div>
                                <h6 class="mb-1">Opacity</h6>
                                <input id="color_picker_alpha" @input="set_opacity($event)" type="range" min="0" max="255" step="1" value="255" class="w-100" v-model="formData.background.opacity" />
                            </div>
                            <div :class="toolbar == 'text' ? '' : 'd-none'">
                                <h6 class="mb-2">Text</h6>
                                <div class="row mb-4">
                                    <div class="col-9 px-3">
                                        <select name="" id="" class="form-control bg-transparent text-white w-100" v-model="slideFontStyle" @change="selectFontStyle($event)">
                                            <option value="unset" selected>Regular</option>
                                            <option value="bold">Bold</option>
                                            <option value="italic">Italic</option>
                                            <option value="underline">Underline</option>
                                        </select>
                                    </div>
                                    <div class="col-3 pr-3">
                                        <select name="" id="" class="form-control bg-transparent text-white w-100" v-model="slideFontSize" @change="selectFontSize($event)">
                                            <option :value="val+12" v-for="val in 12" :key="val">{{ val+12 }}</option>
                                        </select>
                                    </div>
                                </div>
                                <h6 class="mb-2">Warna Text</h6>
                                <div class="row m-0 mb-4">
                                    <div id="color_wrapper_text" class="col-1">
                                        <input id="color_picker_text" @input="set_color_text($event)" type="color" data-color="black" class="mr-2" v-model="color_picker_text">
                                    </div>
                                    <div class="col-11 pl-3">
                                        <input type="text" class="form-control border-none border-radius w-100" style="background-color: #2F2F2F; color: #8C8C8C;" v-model="slideTextColor" readonly>
                                    </div>
                                </div>
                                <h6 class="mb-2">Opacity</h6>
                                <input id="color_picker_text_alpha" @input="set_opacity_text($event)" type="range" min="0" max="255" step="1" value="255" class="w-100" v-model="color_picker_text_alpha" />
                                <button class="btn btn-purple w-100 mt-3" @click="addText()">Tambahkan Teks</button>
                            </div>
                            <div :class="toolbar == 'image' ? '' : 'd-none'">
                                <h6>Gambar</h6>
                                <div class="row">
                                    <div class="col p-3">
                                        <div class="mb-3">
                                            <input type="text" class="form-control input-search" placeholder="cari gambar" v-model="searchImage" @change="getImageSearch()">
                                        </div>
                                    </div>
                                </div>
                                <div class="toolbar-content-detail">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer" v-for="(value, index) in list_images" :key="index" @click="addImage(value.urls.small)">
                                            <img :src="value.urls.thumb" alt="" class="w-100">
                                        </div>
                                        <div v-if="isLoadingSearch">
                                            <div class="col-md-4 col-sm-6 col-xs-12 p-3 cursor-pointer" v-for="index in 9" :key="index">
                                                <b-skeleton-img></b-skeleton-img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="toolbar == 'music' ? '' : 'd-none'">
                                <h6>Musik</h6>
                                <div class="row">
                                    <div class="col p-3">
                                        <div class="mb-3">
                                            <input type="text" class="form-control input-search" placeholder="cari musik">
                                        </div>
                                    </div>
                                </div>
                                <div class="toolbar-content-detail">
                                    <div class="row">
                                        <div class="col-12 px-3">
                                            <div class="card-music d-flex p-3 mb-3" v-for="(value, index) in list_music" :key="index">
                                                <img src="@/assets/images/icons/ic_play_music.svg" class="img-play" alt="" @click="playMusic(index)">
                                                <h6 class="my-auto ml-3">{{ value.name }}</h6>
                                                <button type="button" class="ml-auto btn btn-used-music text-white w-25" v-if="index == index_music">✓</button>
                                                <button type="button" class="ml-auto btn btn-use-music text-white w-25" v-else @click="useMusic(index)">Use</button>
                                                <audio :id="value.name.split(' ').join('-')" :src="path_mlp + value.path.replace('src/', '')"></audio>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="position-absolute w-100 h-100 overlay-disabled-menu" v-if="isPreview || isLoadingProcess"></div>
                        </div>
                        <div class="slide-tools p-3">
                            <div class="my-3 cursor-pointer" @click="setToolbar('avatars')">
                                <img src="@/assets/images/icons/course-toolbar/ic_avatars_active.svg" alt="" class="mb-1" v-if="toolbar == 'avatars'">
                                <img src="@/assets/images/icons/course-toolbar/ic_avatars.svg" alt="" class="mb-1" v-else>
                                <h6 class="label-toolbar" :style="toolbar == 'avatars' ? 'color: #BC3BF2' : 'color: white'">Avatars</h6>
                            </div>
                            <div class="my-3 cursor-pointer" @click="setToolbar('background')">
                                <img src="@/assets/images/icons/course-toolbar/ic_background_active.svg" alt="" class="mb-1" v-if="toolbar == 'background'">
                                <img src="@/assets/images/icons/course-toolbar/ic_background.svg" alt="" class="mb-1" v-else>
                                <h6 class="label-toolbar" :style="toolbar == 'background' ? 'color: #BC3BF2' : 'color: white'">Latar</h6>
                            </div>
                            <div class="my-3 cursor-pointer" @click="setToolbar('text')">
                                <img src="@/assets/images/icons/course-toolbar/ic_text_active.svg" alt="" class="mb-1" v-if="toolbar == 'text'">
                                <img src="@/assets/images/icons/course-toolbar/ic_text.svg" alt="" class="mb-1" v-else>
                                <h6 class="label-toolbar" :style="toolbar == 'text' ? 'color: #BC3BF2' : 'color: white'">Text</h6>
                            </div>
                            <div class="my-3 cursor-pointer" @click="setToolbar('image')">
                                <img src="@/assets/images/icons/course-toolbar/ic_image_active.svg" alt="" class="mb-1" v-if="toolbar == 'image'">
                                <img src="@/assets/images/icons/course-toolbar/ic_image.svg" alt="" class="mb-1" v-else>
                                <h6 class="label-toolbar" :style="toolbar == 'image' ? 'color: #BC3BF2' : 'color: white'">Gambar</h6>
                            </div>
                            <div class="my-3 cursor-pointer" @click="setToolbar('music')">
                                <img src="@/assets/images/icons/course-toolbar/ic_music_active.svg" alt="" class="mb-1" v-if="toolbar == 'music'">
                                <img src="@/assets/images/icons/course-toolbar/ic_music.svg" alt="" class="mb-1" v-else>
                                <h6 class="label-toolbar" :style="toolbar == 'music' ? 'color: #BC3BF2' : 'color: white'">Musik</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <audio id="tts-audio"></audio>
    </section>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Swal from "sweetalert2";
import $ from "jquery"
import { getSearchImage, getMasterMusics, videoGenerator } from "@/services/course/course.service";
import { base_url_machine_learning } from "../../../config/base_url"

export default {
    layouts: {
        default: '~/views/layouts/empty.vue',
    },
    data(){
        return {
            formData: {
                background: {
                    value: '#000000',
                    opacity: 255
                },
                music: {}
            },
            index_music: undefined,
            music_active: undefined,
            list_music: [],
            toolbar: 'avatars',
            
            slides: [
                {
                    text: [],
                    image: [],
                    script: ""
                }
            ],
            slideActive: 0,
            textActive: 0,
            imageActive: 0,
            list_images: [],
            slide_images: [],
            slide_texts: [],
            path_mlp: base_url_machine_learning,
            avatars: [
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Mutia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                },
                {
                    image: "../../../assets/images/avatars/Sovia.svg"
                }
            ],
            slideBgColor: '#171717',
            color_picker: '#171717',
            color_picker_alpha: 255,
            slideTextColor: '#ffffff',
            color_picker_text: '#ffffff',
            color_picker_text_alpha: 255,
            slideFontStyle: 'unset',
            slideFontSize: 14,
            searchImage: '',
            isLoadingSearch: false,
            ratioSlides: {},
            width: 0,
            height: 0,
            x: 0,
            y: 0,
            isAvatar: false,
            
            // Avatar settings
            container: null,
            scene: null,
            camera: null,
            controls: null,
            renderer: null,
            stats: null,
            mixers: [],
            clock: null,
            animation: 2,
            listAnimation: [],

            // Preview
            isPreview: false,
            isPlayPreview: false,
            isLoadingProcess: false
        }
    },
    mounted(){
        this.setRatioWrapper()
        this.setSideBar()
        this.getListMusic()
        $("section.detail-setup-quiz").parent().addClass("m-0")
    },
    methods: {
        setSideBar() {
            this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        },
        getListMusic() {
            getMasterMusics()
            .then(data => {
                if(data.status == true) {
                    this.list_music = data.data
                }
            })
            .catch(err => {
                console.log(err);
            })
        },
        onResize(x, y, width, height) {
            this.slides[this.slideActive].text[this.textActive].x = x
            this.slides[this.slideActive].text[this.textActive].y = y
            this.slides[this.slideActive].text[this.textActive].width = width
            this.slides[this.slideActive].text[this.textActive].height = height
        },
        onDrag(x, y) {
            this.slides[this.slideActive].text[this.textActive].x = x
            this.slides[this.slideActive].text[this.textActive].y = y
        },
        onResizeImage(x, y, width, height) {
            this.imageActive.x = x
            this.imageActive.y = y
            this.imageActive.width = width
            this.imageActive.height = height
        },
        onDragImage(x, y) {
            this.imageActive.x = x
            this.imageActive.y = y
        },
        chooseAvatar() {
            if(!this.isAvatar) {
                this.slide_images.push({});

                setTimeout(() => {
                    this.initAvatar()
                }, 200);
                this.isAvatar = true
            }
        },
        initAvatar() {
            // set container
            this.container = this.$refs.avatarContainer;

            // add stats
            // this.stats = new Stats();
            this.clock = new THREE.Clock();
            // this.container.appendChild(this.stats.dom);

            // Field of view
            let fov = 10

            const aspect = this.container.clientWidth / this.container.clientHeight;
            const near = 0.1; // the near clipping plane
            const far = 1000; // the far clipping plane
            const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
            camera.position.set(0, 2, 12);
            this.camera = camera;

            // create scene
            this.scene = new THREE.Scene();

            // add lights
            const ambientLight = new THREE.HemisphereLight(
                0xffffff, // bright sky color
                0x222222, // dim ground color
                1 // intensity
            );
            const mainLight = new THREE.DirectionalLight(0xffffff, 4.0);
            mainLight.position.set(10, 10, 10);
            this.scene.add(ambientLight, mainLight);

            // add controls
            this.controls = new OrbitControls(this.camera, this.container);
            // this.controls.enabled = false
            this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI * 0.45;
            this.controls.enableZoom = false; //To enable zoom mode on 3d

            // create renderer
            this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            this.renderer.setSize(
                this.container.clientWidth,
                this.container.clientHeight
            );
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.gammaFactor = 2.2;
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            this.renderer.physicallyCorrectLights = true;
            this.container.appendChild(this.renderer.domElement);

            // set aspect ratio to match the new browser window aspect ratio
            this.camera.aspect =
                this.container.clientWidth / this.container.clientHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(
                this.container.clientWidth,
                this.container.clientHeight
            );

            //load model

            const loader = new GLTFLoader();

            const onLoad = (result, position) => {
                this.listAnimation = result.animations

                const model = result.scene.children[0];
                model.position.copy(position);
                model.scale.set(0.5, 0.5, 0.5);

                this.model = model;

                const mixer = new THREE.AnimationMixer(model);
                // this.mixers.push(mixer);

                const animation = this.listAnimation[this.animation];
                const action = mixer.clipAction(animation);
                action.play();

                this.scene.add(model);

                console.log('abcd');
            };

            let parrotPosition = new THREE.Vector3(0, -150, -625);

            loader.load(
                "/three-assets/sovia.gltf",
                (gltf) => onLoad(gltf, parrotPosition),
                undefined,
                undefined
            );

            this.renderer.setAnimationLoop(() => {
                this.update();
                this.render();
            });
        },
        update() {
            const delta = this.clock.getDelta();
            this.mixers.forEach((mixer) => mixer.update(delta));
        },
        render() {
            this.renderer.render(this.scene, this.camera);
        },
        setRatioWrapper() {
            let arrSlides = ['.slide-content-wrapper','#slide-content', '.slide-content-small', '.slide-content-small-active']

            arrSlides.forEach(val => {
                const width = $(val).width();
                const height = width / 16 * 9;
    
                $(val).height(height)

                this.ratioSlides[val] = {
                    width: width,
                    height: height
                }
            })

            // const containerHeight = $('.container-slide').height();
            // $('.slide-preview').css({"maxHeight":(containerHeight+(containerHeight/3)) + "px"});
        },
        getImageSearch() {
            var vm = this
            vm.list_images = []
            vm.isLoadingSearch = true
            getSearchImage(vm.searchImage)
            .then((response) => {
                if (response.status) {
                    vm.list_images = response.data.results;
                } else {
                    vm.list_images = [];
                }
                vm.isLoadingSearch = false
            })
            .catch((err) => {
                console.log("data err", err);
                vm.isLoadingSearch = false
            });
        },
        set_color(e){
            var vm = this

            vm.color_picker = e.target.value
            vm.formData.background.value = e.target.value + (vm.color_picker_alpha == 255 ? "" : parseInt(vm.color_picker_alpha).toString(16).padStart(2, "0"))
            document.getElementById("color_wrapper").style.backgroundColor = vm.formData.background.value
        },
        set_opacity(e){
            var vm = this

            vm.color_picker_alpha = e.target.value
            vm.formData.background.opacity = e.target.value
            vm.formData.background.value = vm.color_picker + (e.target.value == 255 ? "" : parseInt(e.target.value).toString(16).padStart(2, "0"))
            document.getElementById("color_wrapper").style.backgroundColor = vm.formData.background.value
        },
        set_color_text(e){
            var vm = this
            vm.color_picker_text = e.target.value
            document.getElementById("color_wrapper_text").style.backgroundColor = e.target.value + (vm.color_picker_text_alpha == 255 ? "" : parseInt(vm.color_picker_text_alpha).toString(16).padStart(2, "0"));
            vm.slideTextColor = e.target.value + (vm.color_picker_text_alpha == 255 ? "" : parseInt(vm.color_picker_text_alpha).toString(16).padStart(2, "0"));
            if (vm.slides[vm.slideActive].text.length > 0) {
                vm.slides[vm.slideActive].text[vm.textActive].color = e.target.value + (vm.color_picker_text_alpha == 255 ? "" : parseInt(vm.color_picker_text_alpha).toString(16).padStart(2, "0"));
                vm.slides[vm.slideActive].text[vm.textActive].color = e.target.value
                vm.slides[vm.slideActive].text[vm.textActive].opacity = vm.color_picker_text_alpha
            }

            console.log(vm.slides);
        },
        set_opacity_text(e){
            var vm = this
            vm.color_picker_text_alpha = e.target.value
            document.getElementById("color_wrapper_text").style.backgroundColor = vm.color_picker_text + (e.target.value == 255 ? "" : parseInt(e.target.value).toString(16).padStart(2, "0"));
            vm.slideTextColor = vm.color_picker_text + (e.target.value == 255 ? "" : parseInt(e.target.value).toString(16).padStart(2, "0"));
            if (vm.slides[vm.slideActive].text.length > 0) {
                vm.slides[vm.slideActive].text[vm.textActive].color = vm.color_picker_text + (e.target.value == 255 ? "" : parseInt(e.target.value).toString(16).padStart(2, "0"));
                vm.slides[vm.slideActive].text[vm.textActive].opacity = e.target.value
            }
        },
        selectFontStyle(e) {
            var vm = this
            vm.slides[vm.slideActive].text[vm.textActive].style = e.target.value
            if (e.target.value == 'bold') {
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.textDecoration = 'unset'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontWeight = 'bold'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontStyle = 'unset'
            } else if (e.target.value == 'italic') {
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.textDecoration = 'unset'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontWeight = 'unset'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontStyle = 'italic'
            } else if (e.target.value == 'underline') {
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.textDecoration = 'underline'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontWeight = 'unset'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontStyle = 'unset'
            } else {
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.textDecoration = 'unset'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontWeight = 'unset'
                document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontStyle = 'unset'
            }
        },
        selectFontSize(e) {
            var vm = this
            vm.slides[vm.slideActive].text[vm.textActive].size = e.target.value
            document.getElementById("textarea-"+vm.slideActive+'-'+vm.textActive).style.fontSize = e.target.value+'px'
        },
        activeText(index) {
            var vm = this
            if(vm.toolbar == 'text') {
                document.getElementById("color_wrapper_text").style.backgroundColor = vm.slides[vm.slideActive].text[index].color
            }
            vm.slideTextColor = vm.slides[vm.slideActive].text[index].color
            vm.color_picker_text = vm.slides[vm.slideActive].text[index].color.length > 7 ? vm.slides[vm.slideActive].text[index].color.substring(0, vm.slides[vm.slideActive].text[index].color.length-2) : vm.slides[vm.slideActive].text[index].color;
            vm.color_picker_text_alpha = vm.slides[vm.slideActive].text[index].opacity
            vm.slideFontStyle = vm.slides[vm.slideActive].text[index].style
            vm.slideFontSize = vm.slides[vm.slideActive].text[index].size

            vm.textActive = index

            vm.setRatioWrapper()
        },
        activeSlide(index) {
            var vm = this
            if(vm.toolbar == 'text') {
                if (vm.slides[index].text.length > 0) {
                    document.getElementById("color_wrapper_text").style.backgroundColor = vm.slides[index].text[0].color
                }
            }
            if (vm.slides[index].text.length > 0) {
                vm.slideTextColor = vm.slides[index].text[0].color
                vm.color_picker_text = vm.slides[index].text[0].color.length > 7 ? vm.slides[index].text[0].color.substring(0, vm.slides[index].text[0].color.length-2) : vm.slides[index].text[0].color;
                vm.color_picker_text_alpha = vm.slides[index].text[0].opacity
                vm.slideFontStyle = vm.slides[index].text[0].style
                vm.slideFontSize = vm.slides[index].text[0].size
            }

            vm.slideActive = index
            vm.textActive = 0

            if (vm.slides[index].text.length > 0) {
                setTimeout(() => {
                    vm.slides[index].text.forEach((element, i) => {
                        document.getElementById("textbox-"+index+'-'+i).style.transform = 'translate('+element.x+'px, '+element.y+'px)'
                        document.getElementById("textbox-"+index+'-'+i).style.width = element.width+'px'
                        document.getElementById("textbox-"+index+'-'+i).style.height = element.height+'px'
                        if (element.style == 'bold') {
                            document.getElementById("textarea-"+index+'-'+i).style.textDecoration = 'unset'
                            document.getElementById("textarea-"+index+'-'+i).style.fontWeight = 'bold'
                            document.getElementById("textarea-"+index+'-'+i).style.fontStyle = 'unset'
                        } else if (element.style == 'italic') {
                            document.getElementById("textarea-"+index+'-'+i).style.textDecoration = 'unset'
                            document.getElementById("textarea-"+index+'-'+i).style.fontWeight = 'unset'
                            document.getElementById("textarea-"+index+'-'+i).style.fontStyle = 'italic'
                        } else if (element.style == 'underline') {
                            document.getElementById("textarea-"+index+'-'+i).style.textDecoration = 'underline'
                            document.getElementById("textarea-"+index+'-'+i).style.fontWeight = 'unset'
                            document.getElementById("textarea-"+index+'-'+i).style.fontStyle = 'unset'
                        } else {
                            document.getElementById("textarea-"+index+'-'+i).style.textDecoration = 'unset'
                            document.getElementById("textarea-"+index+'-'+i).style.fontWeight = 'unset'
                            document.getElementById("textarea-"+index+'-'+i).style.fontStyle = 'unset'
                        }
                        document.getElementById("textarea-"+index+'-'+i).style.fontSize = element.size+'px'
                    })
                },100)
            }
            vm.setRatioWrapper()
        },
        setToolbar(value) {
            this.toolbar = value

            if(document.getElementById("color_wrapper_text")) {
                if (this.slides[this.slideActive].text.length > 0) {
                    document.getElementById("color_wrapper_text").style.backgroundColor = this.slides[this.slideActive].text[0].color
                }
            }
        },
        addText() {
            var vm = this
            vm.slides[vm.slideActive].text.push({
                value: 'Masukkan Teks',
                color: vm.color_picker_text,
                opacity: vm.color_picker_text_alpha,
                style: vm.slideFontStyle,
                size: vm.slideFontSize,
                width: 100,
                height: 100,
                x: 0,
                y: 0
            })

            vm.textActive = vm.slides[vm.slideActive].text.length-1
            setTimeout(() => {
                vm.slides[vm.slideActive].text.forEach((element, i) => {
                    if (element.style == 'bold') {
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.textDecoration = 'unset'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontWeight = 'bold'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontStyle = 'unset'
                    } else if (element.style == 'italic') {
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.textDecoration = 'unset'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontWeight = 'unset'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontStyle = 'italic'
                    } else if (element.style == 'underline') {
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.textDecoration = 'underline'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontWeight = 'unset'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontStyle = 'unset'
                    } else {
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.textDecoration = 'unset'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontWeight = 'unset'
                        document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontStyle = 'unset'
                    }
                    document.getElementById("textarea-"+vm.slideActive+'-'+i).style.fontSize = element.size+'px'
                })
            },10)
        },
        addImage(src) {
            var vm = this
            vm.slides[vm.slideActive].image.push({
                src: src,
                width: 100,
                height: 100,
                x: 0,
                y: 0
            })

            vm.imageActive = vm.slides[vm.slideActive].image.length-1

            console.log(vm.slides);
        },
        playMusic(index) {
            var id = this.list_music[index].name.split(' ').join('-')
            var audio = document.getElementById(id);

            if(this.music_active == index) {
                audio.pause();
                audio.currentTime = 0;

                this.music_active = undefined
            }else{
                if(this.music_active != undefined) {
                    var id_playing = this.list_music[this.music_active].name.split(' ').join('-')
                    var playing_audio = document.getElementById(id_playing);
                    playing_audio.pause();
                    playing_audio.currentTime = 0;
                }

                audio.play();

                this.music_active = index
            }
        },
        useMusic(index) {
            this.index_music = index
            this.formData.music = this.list_music[index]
        },
        async submitScript() {
            if(this.music_active != undefined) {
                var id_playing = this.list_music[this.music_active].name.split(' ').join('-')
                var playing_audio = document.getElementById(id_playing);
                playing_audio.pause();
                playing_audio.currentTime = 0;
            }

            this.slides.forEach((val, idx) => {
                val['slide'] = idx + 1;
            })

            const data = {
                course_id: "",
                users_id: "123124",
                payload: {
                    wrapper: this.ratioSlides['#slide-content'],
                    background: this.formData.background,
                    music: this.formData.music,
                    slides: this.slides
                }
            }

            console.log(data);

            this.isLoadingProcess = true

            videoGenerator(data)
            .then((response) => {
                this.isLoadingProcess = false

                if(response.status && response.status == true){
                    console.log(response.data);

                    localStorage.setItem('job_id', response.data.job_id)

                    this.$router.push({ name:"Preview-My-Course" })
                    // this.isPreview = true

                    // var audio = document.getElementById('tts-audio');
                    // audio.src = "data:audio/mp3;base64," + response.data;

                    // if (this.slides[this.slideActive].text.length > 0) {
                    //     setTimeout(() => {
                    //         this.slides[this.slideActive].text.forEach((element, i) => {
                    //             if (element.style == 'bold') {
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.textDecoration = 'unset'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontWeight = 'bold'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontStyle = 'unset'
                    //             } else if (element.style == 'italic') {
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.textDecoration = 'unset'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontWeight = 'unset'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontStyle = 'italic'
                    //             } else if (element.style == 'underline') {
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.textDecoration = 'underline'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontWeight = 'unset'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontStyle = 'unset'
                    //             } else {
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.textDecoration = 'unset'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontWeight = 'unset'
                    //                 document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontStyle = 'unset'
                    //             }
                    //             document.getElementById("textarea-"+this.slideActive+'-'+i).style.fontSize = element.size+'px'
                    //         })
                    //     },100)
                    // }
                }
            })
            .catch(err => {
                console.log(err);
            })
        },
        playPreview() {
            this.isPlayPreview = true

            var audio = document.getElementById('tts-audio');
            audio.play();

            if(this.index_music != undefined) {
                var id_playing = this.list_music[this.index_music].name.split(' ').join('-')
                var playing_audio = document.getElementById(id_playing);
                playing_audio.volume = 0.4;
                playing_audio.play();
            }

            var vm = this

            if(vm.isAvatar) {
                const mixer = new THREE.AnimationMixer(this.model);
                vm.mixers = []
                vm.mixers.push(mixer);

                if(vm.listAnimation.length >= 2) {
                    const animation = vm.listAnimation[2];
                    const action = mixer.clipAction(animation);
                    action.play();
                }
            }

            audio.addEventListener("ended", function(){
                vm.isPlayPreview = false
                vm.mixers = []

                audio.currentTime = 0;
                playing_audio.pause();
                playing_audio.volume = 1;
                playing_audio.currentTime = 0;
            });
        },
        addSlide() {
            this.slides.push({
                text: [],
                image: [],
                script: ""
            })
            
            if(this.toolbar == 'text') {
                document.getElementById("color_wrapper_text").style.backgroundColor = '#ffffff'
            }
            this.slideTextColor = '#ffffff'
            this.color_picker_text = '#ffffff'
            this.color_picker_text_alpha = 255
            this.slideActive = this.slides.length-1
            this.slideFontStyle = 'unset',
            this.slideFontSize = 14

            setTimeout(() => {
                this.setRatioWrapper()
            }, 100);

        },
        duplicateSlide(index) {
            var vm = this;
            const new_color = vm.slides[index].text
            const new_image = vm.slides[index].image
            const new_script = vm.slides[index].script

            this.slides.splice(index+1, 0, {
                text: new_color,
                image: new_image,
                script: new_script
            })

            if(this.toolbar == 'text') {
                if (vm.slides[index].text.length > 0) {
                    document.getElementById("color_wrapper_text").style.backgroundColor = new_color[0].color
                }
            }
            
            if (vm.slides[index].text.length > 0) {
                this.slideTextColor = new_color[0].color
                this.color_picker_text = new_color[0].color.length > 7 ? new_color[0].color.substring(0, new_color[0].color.length-2) : new_color[0].color
                this.color_picker_text_alpha =  new_color[0].opacity
                this.slideFontStyle = new_color[0].style
                this.slideFontSize = new_color[0].size
            }
            this.slideActive = index+1
            this.textActive = 0

            this.setRatioWrapper()
        },
        deleteSlide(index) {
            Swal.fire({
                text: 'Kamu yakin ingin menghapus slide ini?',
                imageUrl: require('@/assets/images/avatars/Sovia Alert.svg'),
                imageWidth: 400,
                imageHeight: 200,
                showCancelButton: true,
                confirmButtonColor: '#6D75F6',
                cancelButtonColor: '#8C8C8C',
                confirmButtonText: 'Iya',
                cancelButtonText: 'Tidak',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    if (index == (this.slides.length - 1)) {
                        this.slides.splice(index, 1)
                        this.slideActive = index-1
                        
                        if(this.toolbar == 'text') {
                            if (this.slides[index-1].text.length > 0) {
                                document.getElementById("color_wrapper_text").style.backgroundColor = this.slides[index-1].text[0].color
                            }
                        }

                        if (this.slides[index-1].text.length > 0) {
                            this.slideTextColor = this.slides[index-1].text[0].color
                            this.color_picker_text = this.slides[index-1].text[0].color.length > 7 ? this.slides[index-1].text[0].color.substring(0, this.slides[index-1].text[0].color.length-2) : this.slides[index-1].text[0].color;
                            this.color_picker_text_alpha = this.slides[index-1].text[0].opacity
                            this.slideFontStyle = this.slides[index-1].text[0].style
                            this.slideFontSize = this.slides[index-1].text[0].size
                        }
                    } else {
                        this.slides.splice(index, 1)
                        this.slideActive = index

                        if(this.toolbar == 'text') {
                            if (this.slides[index].text.length > 0) {
                                document.getElementById("color_wrapper_text").style.backgroundColor = this.slides[index].text[0].color
                            }
                        }
                        
                        if (this.slides[index].text.length > 0) {
                            this.slideTextColor = this.slides[index].text[0].color
                            this.color_picker_text = this.slides[index].text[0].color.length > 7 ? this.slides[index].text[0].color.substring(0, this.slides[index].text[0].color.length-2) : this.slides[index].text[0].color;
                            this.color_picker_text_alpha = this.slides[index].text[0].opacity
                            this.slideFontStyle = this.slides[index].text[0].style
                            this.slideFontSize = this.slides[index].text[0].size
                        }
                    }
                }
            })
        },
    }
}
</script>

<style>
#avatar-container {
    width: 35%;
    height: 100%;
    position: absolute;
    left: 0;
    overflow: hidden !important;
}

#avatar-container div {
    display: none !important;
}

.main-container-fluid {
    height: 100% !important;
    /* margin-left: 0px !important; */
    /* padding: 60px 0px 0px 0px !important; */
}
.slide-preview {
    /* height: 100vh; */
    background: black;
    color: white;
    /* width: 221px !important; */
    max-height: 110vh;
    overflow: scroll;
}

.slide-preview::-webkit-scrollbar, .toolbar-content-detail::-webkit-scrollbar{
    display: none;
}

.slide-content-small {
    background: #171717;
    width: 90%;
    height: 13vh;
    border-radius: 7.91px;
    color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.slide-content-small-active {
    background: #171717;
    width: 90%;
    height: 13vh;
    border-radius: 7.91px;
    border: 1px solid #BC3BF2;
    color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.text-content-small {
    font-size: 8px !important;
    margin-top: 2px !important;
    font-weight: lighter;
}

.slide-content-wrapper {
    background: #171717;
    /* width: 1100px; */
    /* width: 714px; */
    border-radius: 7.91px;
    color: #8C8C8C;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.overlay-slide {
    background-color: #00000080;
    z-index: 1;
}

.overlay-slide img {
    width: 5rem;
}

.slide-content {
    position: relative;
    background: #171717;
    /* width: 1100px; */
    /* width: 714px; */
    border-radius: 7.91px;
    color: #8C8C8C;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.slide-content-script {
    background: #171717;
    /* width: 1100px; */
    /* width: 714px; */
    height: auto;
    border-radius: 7.91px;
    color: #8C8C8C;
}

.toolbar-content {
    background: #121212;
    border-radius: 7.91px;
    color: #8C8C8C;
}

.toolbar-content-detail {
    max-height: 80vh;
    overflow: scroll;
}

.btn-use-music {
    background-color: #3E3E3E;
}

.btn-used-music {
    background-color: #6D75F6;
}

.avatar-active {
    border-radius: 7.91px;
    border: 1px solid #BC3BF2;
}

.slide-tools {
    float: right;
    background: black;
    color: white;
    width: 74px !important;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.overlay-disabled-menu {
    background-color: #00000080;
    left: 0;
    top: 0;
}

.label-toolbar {
    font-size: 10px !important;
}

.text-number-slides {
    font-size: 12px !important;
    width: 5%;
}

.img-play {
    cursor: pointer;
}

textarea.script {
    height: 206px;
    background: transparent;
    border-color: #2F2F2F;
    border-radius: 6px;
}

.con-tab.vs-tabs--content {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding: 15px;
}

.btn-proses {
    background: #6D75F6 !important;
    color: #FFFFFF !important;
    border: none;
}

.card-music {
    background-color: #2F2F2F;
    border-radius: .5rem;
}

/* color picker background */
#color_wrapper {
  background-color: #171717;
  display: inline-block;
  visibility: hidden;
}

#color_wrapper::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  outline: black solid 2px;
  border: white solid 3px;
  height: 2rem;
  width: 2rem;
  pointer-events: none;
  background-color: inherit;
  visibility: visible;
  box-sizing: border-box;
  border: 1px solid white;
}

#color_picker {
  opacity: 0;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  pointer-events: initial;
  visibility: visible;
}


#color_picker_alpha {
  filter: grayscale(100%);
  visibility: visible;
}

/* color picker text */
#color_wrapper_text {
  background-color: #ffffff;
  display: inline-block;
  visibility: hidden;
}

#color_wrapper_text::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  outline: black solid 2px;
  border: white solid 3px;
  height: 2rem;
  width: 2rem;
  pointer-events: none;
  background-color: inherit;
  visibility: visible;
  box-sizing: border-box;
  border: 1px solid white;
}

#color_picker_text {
  opacity: 0;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  pointer-events: initial;
  visibility: visible;
}


#color_picker_text_alpha {
  filter: grayscale(100%);
  visibility: visible;
}

.btn-purple {
    background: #6D75F6;
    color: white;
}

.input-search, .input-search:active, .input-search:focus {
    background-color: #171717;
    color: white
}
</style>